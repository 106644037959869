.link-main.selected{
    color: #377DFF !important;
    cursor: default;
}

.link-main {
    color: #757575 !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin-right: 30px;
    text-decoration: none!important;
}
.admin-toolbar header {
    padding: 0px !important;
}

.admin-treatu-logo {
    width: 180px;
}

.admin-toolbar .do-btn .MuiSvgIcon-root {
    color: #0F9FFA;
}

.admin-toolbar ul {
    background-color: #0F9FFA;
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
}

.admin-toolbar ul .MuiListItemIcon-root{
    color: #FFEEBD;
}
.admin-toolbar ul li:hover{
    background-color: #FFEEBD;
    color: #444444;
}

.admin-toolbar ul li:hover .MuiListItemIcon-root{
    color: white;
}
.admin-toolbar .selected{
    background-color: #412209;
}
.admin-toolbar .selected:hover{
    background-color: #412209;
    color: white;
}
.admin-toolbar .selected:hover .MuiListItemIcon-root{
    color: #FFEEBD;
}
.nested-menu-option {
    position: fixed;
    width: 250px;
    margin-left: 3%;
    margin-top: -40px;
    background: #0F9FFA;
}
.MuiDrawer-root .MuiPaper-root {
    background-color: #0F9FFA;
}
.nested-menu-option .MuiListItemText-root {
    opacity: 1;
}
.nested-menu-option .MuiListItemIcon-root {
    margin-right: 24px;
}

.subscribe-button {
    position: fixed !important;
    right: 16px;
    bottom: 16px;
    z-index: 1000;
}

