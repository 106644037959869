.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

/*Override default MUI styles*/
.carousel .carousel-status {
  top: 5px !important;
}

.MuiAccordion-root {
  box-shadow: none !important;
  background-color: #f5f9ff !important;
}

[class*="-MuiAccordion-root"]::before {
  background-color: #f5f9ff !important;
}

[class*="css-"]::before {
  background-color: transparent;
}

[class*="-expandIconWrapper"] {
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.54) !important;
}

.Mui-expanded {
  background-color: #f5f9ff !important;
}

.body-txt {
  text-align: left;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #0F9FFA;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #61dafb;
}

/*****************************/

.box-bg,
.box-bg ul {
  background-color: #f5f9ff !important;
}

.box-bg {
  padding: 32px 0px;
}

.border-box {
  border: 1px solid #0F9FFA;
  border-radius: 20px;
}

.border-box.box-bg {
  padding: 32px;
}

.blue-sm-txt {
  color: #0F9FFA;
}

.link-txt {
  cursor: pointer;
}

div:where(.swal2-container) {
  z-index: 10000 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiDataGrid-columnHeaders {
  background-color: #f5f9ff;
  font-weight: bold;

  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
  }
}

.MuiDataGrid-main {
  height: 367px;
}

.MuiTabPanel-root {
  padding: 12px 0px 0px !important;
}

.offline-circle {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  span {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: gray;
  }
}

.online-circle {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  span {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: green;
  }
}

.busy-circle {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  span {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: red;
  }
}