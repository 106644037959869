.chat-input{
    padding: 0px 3px;
}
.chat-body{
    text-align: left;
    padding: 3px;
    overflow-y: auto;
}
.chat-item-list{
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}