.chat-panel{
    position: fixed;
    right:0;
    bottom:0;
    border-left: 1px solid #0F9FFA;
    border-top: 1px solid #0F9FFA;
    width: 280px;


}

.chat-window-container{
    position: fixed;
    bottom: 0;
    right: 286px;
    display: flex;
    gap: 6px;
    flex-direction: row-reverse;
}

.chat-window{
    width: 286px;
    height: 300px;
    background-color: white;
}
.chat-input div{
    height: 50px;
    text-align: left;


}
.chat-input div:read-write:focus {
    outline: none;
    padding: 3px;
    overflow-y: auto;
}
.chat-body{
    height: 211px;
    width: 280px;
}
.chat-header{
    background-color: #0F9FFA;;
    color: white;
    padding: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chart-item {
    cursor: pointer;
    :hover{
        opacity: 0.6;
        user-select: none;
    }
    :last-child{
        border-bottom: none;
    }
    /*border-bottom: 1px solid #d2d2d2;*/
}

.green-circle{
    background-color: #08FF08;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    display: block;
    position: absolute;
    right:0;
    bottom: 16px;
}
.chat-header .green-circle{
    bottom: 21px;
}